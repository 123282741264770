export default [
  {
    className: "linkcolor",
    path: "/",
    text: "me",
  },
  {
    className: "linkcolor",
    path: "/blogs",
    text: "blog",
  },
  {
    className: "linkcolor",
    path: "/contact",
    text: "contact",
  },
  {
    className: "linkcolor",
    path: "/projects",
    text: "projects",
  },
]
